*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
}

@media (max-width: 3840px) {
  html {
    font-size: 200%;
  }
}

@media (max-width: 2500px) {
  html {
    font-size: 160%;
  }
}

@media (max-width: 2100px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 1500px) {
  html {
    font-size: 72%;
  }
}

@media (max-width: 1300px) {
  html {
    font-size: 62.5%;
  }
}

@media (max-width: 850px) {
  html {
    font-size: 56%;
  }
}

body {
  font-size: 1.6rem;
  font-family: "Tinos", "Roboto", sans-serif;
  color: #2f2c26;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f6f0;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #333d34;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* For loading page */

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__year{
  margin-right: 1.2rem;
}
